<template>
  <main class="manage-teach-learn">
    <b-tabs align="center">
      <b-tab title="School Management" active>
        <template #title>
          <img
            :src="require('../assets/images/icons/school-management.png')"
            alt=""
          />
          <p>School Management</p>
        </template>
        <div class="tabContent tab1SM">
          <b-container class="laptopContainer" fluid>
            <b-row>
              <b-col>
                <h1>Bring your office anywhere</h1>
                <p>
                  The place where you are should not be a <br />
                  limit of your control in your business.
                </p>
              </b-col>
            </b-row>
            <img
              :src="
                require('../assets/images/manage/brooke-cagle-LCcFI_26diA-unsplash.png')
              "
              alt=""
            />
          </b-container>
          <b-container class="container2" fluid>
            <b-row>
              <b-col>
                <h1>Schedule Monitoring</h1>
                <p>
                  Bulletin boards no more, you can check <br />
                  each schedules via your phone as well as<br />
                  room availability for your next lab class.
                </p>
              </b-col>
              <b-col cols="7">
                <img :src="require('../assets/images/manage/schedule-monitoring.png')" alt="" />
              </b-col>
            </b-row>
          </b-container>
          <b-container class="container3" fluid>
            <b-row>
              <b-col cols="7">
                <img :src="require('../assets/images/manage/centralizedfeaturesacrossdepartments.png')" alt="" />
              </b-col>
              <b-col>
                <h1>
                  Centralized features across <br />
                  departments
                </h1>
                <p>
                  Data is the most important part of your school, <br />
                  we want your data to be as reliable and updated<br />
                  as possible across different departments to <br />
                  eliminate miscommunication.
                </p>
              </b-col>
            </b-row>
          </b-container>
          <b-container class="greenContainer" fluid>
            <b-row>
              <b-col>
                <p>
                  Our platform is dedicated to help school leaders and admin
                  <br />
                  have an accessible platform for your data that you can access
                  anytime and <br />
                  anywhere even if you are not in your office. <br />
                </p>
              </b-col>
            </b-row>
            <img :src="require('../assets/images/home/greenBg.png')" alt="" />
          </b-container>
          <b-container class="partnershipForm" fluid>
            <b-row>
              <b-col class="colLeft">
                <h1>
                  Be part of our Beta-School <br />
                  partnership program
                </h1>
                <p>
                  Our experts are with you each step<br />
                  of the way to help your school <br />
                  goals and increase your <br />
                  success.
                </p>
              </b-col>
              <b-col class="colRight">
                <h4>
                  Let us know what you need and we’ll be happy to <br />
                  provide you the perfect platform for your school.
                </h4>

                <b-form>
                  <b-row>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="First Name*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="name"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="Last Name*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="email"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="Business Name*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="name"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="Mobile Name*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="email"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="Country*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="name"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="Which Type of School are you?*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="email"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <span class="consent">
                    I consent to receive communications from whiteStrings
                    containing news, updates and<br />
                    promotions about whiteStrings and its products and services.
                    I understand that I can withdraw<br />
                    my consent at anytime.
                  </span>

                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="status"
                    name="checkbox-1"
                    value="accepted"
                    unchecked-value="not_accepted"
                    class="checkboxWs"
                  >
                    I agree to receive email and mobile communications from
                    whiteStrings.*
                  </b-form-checkbox>

                  <span class="consent">
                    By clicking submit below, you consent to allow whiteStrings
                    to store and process the personal <br />
                    information submitted above to provide you the content
                    requested.
                  </span>
                  <div class="btnContainer">
                    <b-button class="btnRegister">Register</b-button>
                  </div>
                </b-form>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-tab>
      <b-tab title="Leaning Management">
        <template #title>
          <img
            :src="require('../assets/images/icons/learning-management.png')"
            alt=""
          />
          <p>Learning Management</p>
        </template>
        <div class="tabContent tab2SM">
          <b-container class="laptopContainer" fluid>
            <b-row>
              <b-col>
                <h1>Recreating the Classroom Experience</h1>
                <p>
                  Learning is easier than before thanks to <br />
                  whiteStrings virtual classroom experience.
                </p>
              </b-col>
            </b-row>
            <img
              :src="
                require('../assets/images/manage/giovanni-gagliardi-fvT3t9iOaJI-unsplash.png')
              "
              alt=""
            />
          </b-container>
          <b-container class="container2" fluid>
            <b-row>
              <b-col>
                <h1>Asynchronized Learning</h1>
                <p>
                  The place where you are should not be a <br />
                  limit of your control in your business.
                </p>
              </b-col>
              <b-col cols="7">
                <img :src="require('../assets/images/manage/asynchronized-learning.png')" alt="" />
              </b-col>
            </b-row>
          </b-container>
          <b-container class="container3" fluid>
            <b-row>
              <b-col cols="7">
                <img :src="require('../assets/images/manage/student-progression-analytics.png')" alt="" />
              </b-col>
              <b-col>
                <h1>
                  Student progression <br />
                  analytics
                </h1>
                <p>
                  The place where you are should not be a <br />
                  limit of your control in your business.
                </p>
              </b-col>
            </b-row>
          </b-container>
          <b-container class="greenContainer" fluid>
            <b-row>
              <b-col>
                <p>
                  Our learning platform is build by the educators themselves,
                  solving <br />
                  all challenges that the educators currently have in their day
                  to day routine, <br />
                  whiteStrings provided the platform that will automate
                  processes that are <br />
                  not related to learning to help educators focused on teaching
                  their students. <br />
                </p>
              </b-col>
            </b-row>
            <img :src="require('../assets/images/home/greenBg.png')" alt="" />
          </b-container>
          <b-container class="partnershipForm" fluid>
            <b-row>
              <b-col class="colLeft">
                <h1>
                  Be part of our Beta-School <br />
                  partnership program
                </h1>
                <p>
                  Our experts are with you each step<br />
                  of the way to help your school <br />
                  goals and increase your <br />
                  success.
                </p>
              </b-col>
              <b-col class="colRight">
                <h4>
                  Let us know what you need and we’ll be happy to <br />
                  provide you the perfect platform for your school.
                </h4>

                <b-form>
                  <b-row>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="First Name*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="name"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="Last Name*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="email"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="Business Name*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="name"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="Mobile Name*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="email"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="Country*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="name"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="Which Type of School are you?*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="email"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <span class="consent">
                    I consent to receive communications from whiteStrings
                    containing news, updates and<br />
                    promotions about whiteStrings and its products and services.
                    I understand that I can withdraw<br />
                    my consent at anytime.
                  </span>

                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="status"
                    name="checkbox-1"
                    value="accepted"
                    unchecked-value="not_accepted"
                    class="checkboxWs"
                  >
                    I agree to receive email and mobile communications from
                    whiteStrings.*
                  </b-form-checkbox>

                  <span class="consent">
                    By clicking submit below, you consent to allow whiteStrings
                    to store and process the personal <br />
                    information submitted above to provide you the content
                    requested.
                  </span>
                  <div class="btnContainer">
                    <b-button class="btnRegister">Register</b-button>
                  </div>
                </b-form>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-tab>
      <b-tab title="Academic Community">
        <template #title>
          <img
            :src="require('../assets/images/icons/academic-community.png')"
            alt=""
          />
          <p>Academic Community</p>
        </template>
        <div class="tabContent tab3SM">
          <b-container class="laptopContainer" fluid>
            <b-row>
              <b-col>
                <h1>Learn, Share and Collaborate</h1>
                <p>
                  whiteStrings enables teachers as well as <br />
                  students discover a new world of <br />
                  learners and educators outside <br />
                  their school to have a brand new <br />
                  perspective when it come to <br />
                  school environment.
                </p>
              </b-col>
            </b-row>
            <img
              :src="
                require('../assets/images/manage/beth-macdonald-mbND4xtrlVY-unsplash.png')
              "
              alt=""
            />
          </b-container>
          <b-container class="container2" fluid>
            <b-row>
              <b-col>
                <h1>Educators Community</h1>
                <p>
                  Share your expertise or learn new ways of <br />
                  teaching with your fellow educators to create <br />
                  a new learning environment for your students.
                </p>
              </b-col>
              <b-col cols="7">
                <img
                  :src="
                    require('../assets/images/manage/visuals-ufK-deiLqY8-unsplash.png')
                  "
                  alt=""
                />
              </b-col>
            </b-row>
          </b-container>
          <b-container class="container3" fluid>
            <b-row>
              <b-col cols="7">
                <img :src="require('../assets/images/manage/sm3.png')" alt="" />
              </b-col>
              <b-col>
                <h1>Learners Community</h1>
                <p>
                  Discover what learners do to be an <br />
                  effective student in class & showcase <br />
                  your knowledge to others and be an <br />
                  inspiration with the learners around you.
                </p>
              </b-col>
            </b-row>
          </b-container>
          <b-container class="greenContainer" fluid>
            <b-row>
              <b-col>
                <p>
                  We believe that learning is not limited to students but to
                  educators as well, we want to <br />
                  create a community where both learners and educators learn
                  from others or share <br />
                  their expertise to others and discover a whole new perspective
                  in both teaching and <br />
                  in learning.
                </p>
              </b-col>
            </b-row>
            <img :src="require('../assets/images/home/greenBg.png')" alt="" />
          </b-container>
          <b-container class="partnershipForm" fluid>
            <b-row>
              <b-col class="colLeft">
                <h1>
                  Be part of our Beta-School <br />
                  partnership program
                </h1>
                <p>
                  Our experts are with you each step<br />
                  of the way to help your school <br />
                  goals and increase your <br />
                  success.
                </p>
              </b-col>
              <b-col class="colRight">
                <h4>
                  Let us know what you need and we’ll be happy to <br />
                  provide you the perfect platform for your school.
                </h4>

                <b-form>
                  <b-row>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="First Name*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="name"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="Last Name*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="email"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="Business Name*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="name"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="Mobile Name*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="email"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="Country*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="name"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="Which Type of School are you?*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="email"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <span class="consent">
                    I consent to receive communications from whiteStrings
                    containing news, updates and<br />
                    promotions about whiteStrings and its products and services.
                    I understand that I can withdraw<br />
                    my consent at anytime.
                  </span>

                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="status"
                    name="checkbox-1"
                    value="accepted"
                    unchecked-value="not_accepted"
                    class="checkboxWs"
                  >
                    I agree to receive email and mobile communications from
                    whiteStrings.*
                  </b-form-checkbox>

                  <span class="consent">
                    By clicking submit below, you consent to allow whiteStrings
                    to store and process the personal <br />
                    information submitted above to provide you the content
                    requested.
                  </span>
                  <div class="btnContainer">
                    <b-button class="btnRegister">Register</b-button>
                  </div>
                </b-form>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-tab>
      <b-tab title="Data & Analytics">
        <template #title>
          <img
            :src="require('../assets/images/icons/data-and-analytics.png')"
            alt=""
          />
          <p>Data & Analytics</p>
        </template>
        <div class="tabContent tab4SM">
          <b-container class="laptopContainer" fluid>
            <b-row>
              <b-col>
                <h1>Big Data, Big Decisions</h1>
                <p>
                  Your data is as important as making crucial decisions <br />
                  in your business. whiteStrings help you organize <br />
                  and eliminate redundancy for a more efficient <br />
                  and reliable reports to help you on your next<br />
                  big decision.
                </p>
              </b-col>
            </b-row>
            <img
              :src="
                require('../assets/images/manage/muhammad-faiz-zulkeflee-alw-CwGFmwQ-unsplash.png')
              "
              alt=""
            />
          </b-container>
          <b-container class="container2" fluid>
            <b-row>
              <b-col>
                <h1>Eliminate data redundancy</h1>
                <p>
                  No more duplicated and unnecessary data that <br />
                  make your report unreliable. whiteStrings will <br />
                  gather all the data from all departments and <br />
                  you can monitor it in one window for your <br />
                  convenience.
                </p>
              </b-col>
              <b-col cols="7">
                <img
                  :src="
                    require('../assets/images/manage/visuals-ufK-deiLqY8-unsplash.png')
                  "
                  alt=""
                />
              </b-col>
            </b-row>
          </b-container>
          <b-container class="container3" fluid>
            <b-row>
              <b-col cols="7">
                <img :src="require('../assets/images/manage/sm3.png')" alt="" />
              </b-col>
              <b-col>
                <h1>Custom Reports</h1>
                <p>
                  Reports are crucial to your decisions, create <br />
                  customized analytics based from your needs. <br />
                  whiteStrings provides you detailed analytics<br />
                  in real-time for an accurate report for your <br />
                  school.
                </p>
              </b-col>
            </b-row>
          </b-container>
          <b-container class="greenContainer" fluid>
            <b-row>
              <b-col>
                <p>
                  School owners needs to be equipped with modern day tools as
                  technology <br />
                  progress, the communication between the school and the owner
                  itself becomes <br />
                  a challenge. We want to bridge the gap between the school
                  leaders and its <br />
                  departments to maintain quality control and accurate reports
                  to ensure that <br />
                  every decision is the right one.
                </p>
              </b-col>
            </b-row>
            <img :src="require('../assets/images/home/greenBg.png')" alt="" />
          </b-container>
          <b-container class="partnershipForm" fluid>
            <b-row>
              <b-col class="colLeft">
                <h1>
                  Be part of our Beta-School <br />
                  partnership program
                </h1>
                <p>
                  Our experts are with you each step<br />
                  of the way to help your school <br />
                  goals and increase your <br />
                  success.
                </p>
              </b-col>
              <b-col class="colRight">
                <h4>
                  Let us know what you need and we’ll be happy to <br />
                  provide you the perfect platform for your school.
                </h4>

                <b-form>
                  <b-row>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="First Name*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="name"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="Last Name*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="email"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="Business Name*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="name"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="Mobile Name*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="email"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="Country*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="name"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        id="input-group-1"
                        label="Which Type of School are you?*"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          type="email"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <span class="consent">
                    I consent to receive communications from whiteStrings
                    containing news, updates and<br />
                    promotions about whiteStrings and its products and services.
                    I understand that I can withdraw<br />
                    my consent at anytime.
                  </span>

                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="status"
                    name="checkbox-1"
                    value="accepted"
                    unchecked-value="not_accepted"
                    class="checkboxWs"
                  >
                    I agree to receive email and mobile communications from
                    whiteStrings.*
                  </b-form-checkbox>

                  <span class="consent">
                    By clicking submit below, you consent to allow whiteStrings
                    to store and process the personal <br />
                    information submitted above to provide you the content
                    requested.
                  </span>
                  <div class="btnContainer">
                    <b-button class="btnRegister">Register</b-button>
                  </div>
                </b-form>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-tab>
    </b-tabs>
  </main>
</template>
<script>
export default {
  name: "ManageTeachLearn",
};
</script>
<style lang="scss">
@import "@/assets/styles/manageteachlearn.scss";
</style>
